// $(document).ready(function() {
//   dropdown('.language-dropdown-web')
//   dropdown('.language-dropdown-mobile')

//   function dropdown(el){
//     var dropdown = $(el);
//     var currentLanguage = dropdown.find('.current-language');
//     var optionsList = dropdown.find('.language-options');
//     var options = optionsList.find('li');

//     currentLanguage.click(function() {
//       optionsList.toggle();
//     });

//     options.click(function() {
//       var selectedLanguage = $(this).data('lang');
//       var selectedFlag = $(this).data('flag');

//       currentLanguage.find('.flag').text(selectedFlag);
//       currentLanguage.find('.language').text($(this).text());
//       optionsList.hide();

//       var url = window.location.href.split('?')[0]; // Remove query parameters
//       window.location.href = url + '?locale=' + selectedLanguage;
//     });

//     var initialLanguage = $('body').data('language');

//     if (initialLanguage) {
//       var selectedOption = options.filter('[data-lang="' + initialLanguage + '"]');
//       if (selectedOption.length > 0) {
//         var selectedFlag = selectedOption.data('flag');
//         currentLanguage.find('.flag').text(selectedFlag);
//         currentLanguage.find('.language').text(selectedOption.text());
//       }
//     }
//   }
// });

$(document).ready(function() {
  dropdown('.language-dropdown-web')
  dropdown('.language-dropdown-mobile')

  function dropdown(el){
    var dropdown = $(el);
    var currentLanguage = dropdown.find('.current-language');
    var optionsList = dropdown.find('.language-options');
    var options = optionsList.find('li');

    currentLanguage.click(function(event) {
      event.stopPropagation();
      optionsList.toggle();
    });

    options.click(function() {
      var selectedLanguage = $(this).data('lang');
      var selectedFlagIcon = $(this).find('.language-icon').attr('src');

      currentLanguage.find('.language').text($(this).text());
      currentLanguage.find('.language-icon').attr('src', selectedFlagIcon);
      optionsList.hide();

      var url = window.location.href.split('?')[0]; // Remove query parameters
      var urlWithLocale = url + '?locale=' + selectedLanguage;

      // Obtención de los queryParams
      var queryParams = window.location.search;
      if (queryParams) {
        queryParams = queryParams.replace('?', '&');
        queryParams = queryParams.replace(/&locale=[^&]*/, ''); // Se elimina locale si existe
      }

      var redirectTo = queryParams ? urlWithLocale + queryParams : urlWithLocale
      window.location.href = redirectTo;
      // window.location.href = url + '?locale=' + selectedLanguage;
    });

    $(document).click(function() {
      optionsList.hide();
    });

    var initialLanguage = $('body').data('language');

    if (initialLanguage) {
      var selectedOption = options.filter('[data-lang="' + initialLanguage + '"]');
      if (selectedOption.length > 0) {
        var selectedFlagIcon = selectedOption.find('.language-icon').attr('src');

        currentLanguage.find('.language-icon').attr('src', selectedFlagIcon);
        currentLanguage.find('.language').text(selectedOption.text());
      }
    }
  }
});
