$(document).ready(function(){
    if(checkModal()){
        openModal();
    }
    
    function checkModal(){
      return $('#notificationModal').length > 0 ? true : false;
    }
    
    function openModal(){
      $('#notificationModal').modal('show');
    }
});