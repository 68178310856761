const config = require('../../../../config/locales_json/packs/public/filter_public.json');
const language = $('body').data("language");

var filterCountry = $('#form_co')
var filterArea = $('#form_category_area')
var filterClient = $('#form_client')
var filterBenefits = $('#form_benefits')

// FILTRO PAÍS
if (filterCountry.length > 0) {
	filterCountry.selectize({
		delimiter: ",",
		create: false,
		closeAfterSelect: true
	});
}

// FILTRO ÁREA
if (filterArea.length > 0) {
	filterArea.selectize({
		delimiter: ",",
		sortField: 'text',
		create: false,
		closeAfterSelect: true
	});
}

// FILTRO EMPRESA
if (filterClient.length > 0) {
	filterClient.selectize({
		delimiter: ",",
		sortField: 'text',
		create: false,
		closeAfterSelect: true
	});
}

// FILTRO BENEFICIOS
if (filterBenefits.length > 0) {
	filterBenefits.selectize({
		delimiter: ",",
		sortField: 'text',
		create: false,
		closeAfterSelect: true
	});
}

$("#form-filter").on("click", "#clean_form", function(e){
	e.preventDefault();
	document.getElementById("form-filter").reset();
	window.jQuery("button.btn-fj-action").trigger('click');
});

function cleanForm(){
	$("#form-filter").reset();
	$("#form-filter").submit();
}

$("#form-filter-home").on("click", "#send-form", function(e){
	e.preventDefault();

	var error = 0

	var semanticField = $("#form-filter-home #semantic-filter");

	if(!semanticField.val()){
		error++
	}

	if(!error){
		const success = getTranslationValue( config, [language, "form-filter-home-send-form", "success"], 'Buscando...');

		$(this).val(success)
		$("#form-filter-home").submit()
	} else {
		const swalFireTitle = getTranslationValue( config, [language, "form-filter-home-send-form", "error-Swal-fire", "title"], 'Lo sentimos! por favor debe ingresar un texto en el buscador');
		const swalFireConfirmButtonText = getTranslationValue( config, [language, "form-filter-home-send-form", "confirmButtonText", "title"], 'Ok!');

		Swal.fire({
			title: swalFireTitle,
			icon: 'error',
			buttonsStyling: false,
			confirmButtonText: swalFireConfirmButtonText,
			confirmButtonClass: 'btn btn-default'
		});
	}
})

// Copiar el link
$('a#ofert_copy_link').on('click', function(e) {

	const copyLinkText = getTranslationValue( config, [language, "copy_link", "text"], 'URL copiada!');

    var $temp = $("<input>");
    var $url = $(this).data('url');
    $("body").append($temp);
    $temp.val($url).select();
    document.execCommand("copy");
    $temp.remove();
    Swal.fire({icon: 'success', text: copyLinkText, showConfirmButton: true });
});

// compartir en whatsapp
$('.social-share a.whatsapp').on('click', function(e) {
	const whatsappText = getTranslationValue( config, [language, "whatsapp", "text"], 'Mira esta oferta 🚀 ');

	// collet the user input
	var message = whatsappText+$(this).attr("data-url");
	// JavaScript function to open URL in new window
	window.open( "whatsapp://send?text=" + message, '_blank');
});

// compartir en linkedin
$('.social-share a.linkedin').on('click', function(e) {
	const linkedinText = getTranslationValue( config, [language, "linkedin", "text"], 'Mira esta oferta 🚀 ');
	
	// collet the user input
	var message = linkedinText+$(this).attr("data-url");
	// JavaScript function to open URL in new window
	window.open('https://www.linkedin.com/shareArticle?mini=true&url='+ $(this).attr("data-url") +'&title=' + message +'&source=firstjob.me',
		'width=800,height=600'
	);

	return false;
});

// Inicializa tooltips de la ubicación del país
$(document).ready(function(){
	$('.location-job-tooltip').tooltip({
		container: 'body',
		placement: 'right',
		html: true,
		trigger: 'manual'
	});

	// Manejar eventos para abrir y cerrar el tooltip manualmente
	$('.location-job-tooltip').on('mouseenter', function () {
			$(this).tooltip('show');
	}).on('mouseleave', function () {
			$(this).tooltip('hide');
	});
});


function getTranslationValue(config, keys, defaultValue) {
	if (keys.length <= 1) return defaultValue;
  
	let currentTranslation;
	let firstIteration = true;
  
	for (let key of keys) {

	  if (firstIteration) {

		if (config[key]) {
		  currentTranslation = config[key];
		  firstIteration = false;
		} else {
		  firstIteration = false;
		  return defaultValue;
		}
	  } else {

		if (currentTranslation[key]) {
		  currentTranslation = currentTranslation[key];
		} else {
		  return defaultValue;
		}
	  }
	}

	return currentTranslation;
}