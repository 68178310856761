const config = require('../../../../config/locales_json/packs/public/postulation_user.json');
const language = $('body').data("language");

$("#form-postulation-questions #postulation-questions").on("click", function(e){
  e.preventDefault();

  var form = check_inputs_questions();

  if(!form){
    return false;
  } else {
    const success = getTranslationValue( config, [language, "postulation-questions", "success"], 'Postulando...');

    $(this).text(success)
    $(this).addClass("disabled")
    $("#form-postulation-questions").submit();
  }
})


if ($("#alert-success-cv").is(':visible')) {

  const swalFireTitle = getTranslationValue( config, [language, "alert-success-cv", "Swal-fire","tile"], 'Felicidades!!');
  const swalFireText = getTranslationValue( config, [language, "alert-success-cv", "Swal-fire","text"], "El perfil fue creado correctamente, Ya puedes comenzar tu proceso de postulación.");
  const swalFireConfirmButtonText = getTranslationValue( config, [language, "alert-success-cv", "Swal-fire","confirmButtonText"], "Aceptar");

  Swal.fire({
    title: swalFireTitle,
    text: swalFireText,
    icon: "success",
    buttonsStyling: false,
    confirmButtonText: swalFireConfirmButtonText,
    customClass: {
      confirmButton: "btn btn-default"
    }
  })
}

$("#job-overview-sidebar").on("click", "#postulation-form button.restrict-postulation", function(e){
  e.preventDefault();

  const swalFireHTML = getTranslationValue( config, [language, "postulation-form-restrict-postulation", "Swal-fire","html"], "<p class='message-modal message-modal-title'>🚨 Atención 🚨</p> <p class='message-modal message-modal-p'>La oferta a la que estás postulando no está enfocada a tu país de residencia y tendrás menos posibilidades de quedar seleccionado/a.</p> <p class='message-modal message-modal-p'>Si no es problema, puedes continuar con la postulación si deseas 🚀</p>");
  const swalFireConfirmButtonText = getTranslationValue( config, [language, "postulation-form-restrict-postulation", "Swal-fire","confirmButtonText"], "Aceptar");
  const swalFireCancelButtonText = getTranslationValue( config, [language, "postulation-form-restrict-postulation", "Swal-fire","cancelButtonText"], "Cancelar");

  Swal.fire({
    html: swalFireHTML,
    icon: "warning",
    buttonsStyling: false,
    confirmButtonText: swalFireConfirmButtonText,
    customClass: {
      confirmButton: "btn btn-default",
      cancelButton: "btn btn-fj-orange"
    },
    showCancelButton: true,
    cancelButtonText: swalFireCancelButtonText,
  }).then(function (result) {
    if (result.isConfirmed) {
      $("#postulation-form").submit();
    }
  });
});

$("#job-overview-sidebar").on("click", "a.restrict-postulation", function(e){
  e.preventDefault();
  var thisLink = $(this);

  const swalFireHTML = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","html"], "<p class='message-modal message-modal-title'>🚨 Atención 🚨</p> <p class='message-modal message-modal-p'>La oferta a la que estás postulando no está enfocada a tu país de residencia y tendrás menos posibilidades de quedar seleccionado/a.</p> <p class='message-modal message-modal-p'>Si no es problema, puedes continuar con la postulación si deseas 🚀</p>");
  const swalFireConfirmButtonText = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","confirmButtonText"], "Aceptar");
  const swalFireCancelButtonText = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","cancelButtonText"], "Cancelar");

  Swal.fire({
    html: swalFireHTML,
    icon: "warning",
    buttonsStyling: false,
    confirmButtonText: swalFireConfirmButtonText,
    customClass: {
      confirmButton: "btn btn-default",
      cancelButton: "btn btn-fj-orange"
    },
    showCancelButton: true,
    cancelButtonText: swalFireCancelButtonText,
  }).then(function (result) {
    if (result.isConfirmed) {
      window.location.href = thisLink.attr("href");
    }
  });
});

$(".header-detail").on("click", ".restrict-postulation", function(e){
  e.preventDefault();
  var thisLink = $(this);

  const swalFireHTML = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","html"], "<p class='message-modal message-modal-title'>🚨 Atención 🚨</p> <p class='message-modal message-modal-p'>La oferta a la que estás postulando no está enfocada a tu país de residencia y tendrás menos posibilidades de quedar seleccionado/a.</p> <p class='message-modal message-modal-p'>Si no es problema, puedes continuar con la postulación si deseas 🚀</p>");
  const swalFireConfirmButtonText = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","confirmButtonText"], "Aceptar");
  const swalFireCancelButtonText = getTranslationValue( config, [language, "header-detail-restrict-postulation", "Swal-fire","cancelButtonText"], "Cancelar");

  Swal.fire({
    html: swalFireHTML,
    icon: "warning",
    buttonsStyling: false,
    confirmButtonText: swalFireConfirmButtonText,
    customClass: {
      confirmButton: "btn btn-default",
      cancelButton: "btn btn-fj-orange"
    },
    showCancelButton: true,
    cancelButtonText: swalFireCancelButtonText,
  }).then(function (result) {
    if (result.value) {
      window.location.href = thisLink.attr("href");
    }
  });
});

function check_inputs_questions(){
  var error = 0;
	var container = $('#form-postulation-questions .row');
	var inputs = container.find($("input").not(".optional"));
  var selects = container.find($("select").not(".optional"));
	var textareas = container.find($("textarea").not(".optional"));

	inputs.each(function() {
    var inputName = $(this).attr('name');
    var inputType = $(this).attr('type');
    var contInput = $(this).parent();
    contInput.find('.err-msg').remove();

    if(inputName != null && inputName != "") {
      if (inputType == "radio" || inputType == "checkbox") {
        inputName = $(this).attr('name');
        isChecked = $('input[name="'+ inputName +'"]:checked').length;

        contInput = $(this).parent().parent();
        contInput.find('.err-msg').remove();

        if (isChecked == 0) {
          const conInputText = getTranslationValue( config, [language, "check_inputs_questions", "input_name_validation","isChecked-contInput"], 'Debes responder esta pregunta');

          contInput.append('<div class="err-msg"><div class="alert alert-custom alert-outline-danger" role="alert"><div class="alert-text">'+conInputText+'</div></div></div></div>');
          error++;
        }
      } else if (inputType == "text" || inputType == "number" || inputType == "hidden") {
        contInput.find('.err-msg').remove();

        if (!$(this).val()) {
          const conInputText = getTranslationValue( config, [language, "check_inputs_questions", "input_name_validation","inputType-contInput"], 'Debes responder esta pregunta');

          contInput.append('<div class="err-msg"><div class="alert alert-custom alert-outline-danger" role="alert"><div class="alert-text">'+conInputText+'</div></div></div></div>');
          error++;
        }
      }
    }
  })

  textareas.each(function() {
    var inputName = $(this).attr('name');
    // var inputType = $(this).attr('type');
    var contInput = $(this).parent();
    contInput.find('.err-msg').remove();

    if (!$(this).val()) {
      const conInputText = getTranslationValue( config, [language, "check_inputs_questions", "textareas-each","contInput"], 'Debes responder esta pregunta');

      contInput.append('<div class="err-msg"><div class="alert alert-custom alert-outline-danger" role="alert"><div class="alert-icon"><i class="fas fa-exclamation-triangle"></i></div><div class="alert-text">'+conInputText+'</div></div></div></div>');
      error++;
    }
  })

  selects.each(function() {
    var inputName = $(this).attr('name');
    var contInput = $(this).parent();
    contInput.find('.err-msg').remove();

    if(inputName != null && inputName != "") {
      if (!$(this).val()) {
        const conInputText = getTranslationValue( config, [language, "check_inputs_questions", "selects-each","contInput"], 'Debes responder esta pregunta');

        contInput.append('<div class="err-msg"><div class="alert alert-custom alert-outline-danger" role="alert"><div class="alert-icon"><i class="fas fa-exclamation-triangle"></i></div><div class="alert-text">'+conInputText+'</div></div></div></div>');
        error++;
      }
    }
  })

  return error == 0 ? true : false;
}

function getTranslationValue(config, keys, defaultValue) {
	if (keys.length <= 1) return defaultValue;
  
	let currentTranslation;
	let firstIteration = true;

	for (let key of keys) {
	  if (firstIteration) {

      if (config[key]) {
        currentTranslation = config[key];
        firstIteration = false;
      } else {
        firstIteration = false;
        return defaultValue;
      }
	  } else {
      
      if (currentTranslation[key]) {
        currentTranslation = currentTranslation[key];
      } else {
        return defaultValue;
      }
	  }
	}

	return currentTranslation;
}
